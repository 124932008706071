import React from 'react'
import './Titr.css'
class Titr extends React.Component {
    

    render() {
        return (
            <div className="titr-main">
                <div className="container">
                <div className="row">
                    <div className="titr">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <button className="btn1">ورود به ایوند</button>
                    <button className="btn2">عضویت سریع</button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 titr-p">
          <p>برای مشاهده پیشنهاد‌های بهتر قسمت علاقه‌مندی‌تان را کامل کنید.</p>
                </div>
                    </div>
               
                </div>
                </div>
               
            </div>
        )
    }
}

export default Titr;
