import React from 'react'
import './Footer.css'
import pic from '../img/support.svg'
import pic2 from '../img/bazaar.png'
import pic3 from '../img/app-store-logo.png'
import pic4 from '../img/parspack.jpg'
import pic5 from '../img/enamad-3.png'
import pic6 from '../img/facebook.svg'
import pic7 from '../img/instagram.svg'
import pic8 from '../img/linkedin.svg'
import pic9 from '../img/twitter.svg'
import pic10 from '../img/telegram.svg'
class Footer extends React.Component {
   

    render() {
        return (
            <div className="footer">
                <div className="footer-head">
                    <div className="container">
                        <div className="row first-row">
                            <h2>دسته بندی‌ها</h2>
                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            
                            <ul>
                                <li><a href="#">رویدادهای علوم انسانی</a></li>
                                <li><a href="#">رویدادهای علوم پایه</a></li>
                                <li><a href="#">رویدادهای غیره</a></li>
                                
                            </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            <ul>
                                <li><a href="#">رویدادهای کسب و کار</a></li>
                                <li><a href="#">رویدادهای فنی، مهندسی و صنعت</a></li>
                                <li><a href="#">رویدادهای خیریه</a></li>
                                <li><a href="#">رویدادهای مذهبی و مناسبتی</a></li>
                                <li><a href="#">رویدادهای پزشکی</a></li>
                            </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            <ul>
                                <li><a href="#">رویدادهای مالی</a></li>
                                <li><a href="#">رویدادهای توسعه فردی و خانواده</a></li>
                                <li><a href="#">رویدادهای تحصیلی</a></li>
                                <li><a href="#">رویدادهای ورزشی</a></li>
                                <li><a href="#">رویدادهای سرگرمی</a></li>
                            </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            <ul>
                                <li><a href="#">رویدادهای تکنولوژی</a></li>
                                <li><a href="#">رویدادهای کارآفرینی</a></li>
                                <li><a href="#">رویدادهای فرهنگی هنری</a></li>
                                <li><a href="#">رویدادهای گردشگری</a></li>
                                <li><a href="#">رویدادهای مدیریت</a></li>
                            </ul>
                            </div>

                        </div>
                       {/*   second row*/} 

                        <div className="row second-row">
                            <h2>شهر‌های پر رویداد</h2>
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            
                            <ul>
                                <li><a href="#">رویدادهای دانشگاه شهید بهشتی</a></li>
                                <li><a href="#">رویدادهای دانشگاه الزهرا</a></li>
                                <li><a href="#">رویدادهای دانشگاه صنعتی اصفهان</a></li>
                                <li><a href="#">رویدادهای دانشگاه آزاد اسلامی واحد نجف آباد</a></li>

                                
                            </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            <ul>
                                <li><a href="#">رویدادهای شهر تهران</a></li>
                                <li><a href="#">رویدادهای شهر اصفهان</a></li>
                                <li><a href="#">رویدادهای شهر مشهد</a></li>
                                <li><a href="#">رویدادهای شهر تبریز</a></li>
                                <li><a href="#">رویدادهای شهر شیراز</a></li>
                            </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            <ul>
                                <li><a href="#">رویدادهای شهر کرج</a></li>
                                <li><a href="#">رویدادهای شهر قم</a></li>
                                <li><a href="#">رویدادهای شهر اراک</a></li>
                                <li><a href="#">رویدادهای شهر قدس</a></li>
                                <li><a href="#">رویدادهای شهر اهواز</a></li>
                            </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                            <ul>
                                <li><a href="#">رویدادهای حوالی جغرافیایی دانشگاه شریف</a></li>
                                <li><a href="#">رویدادهای دانشگاه امیرکبیر</a></li>
                                <li><a href="#">رویدادهای دانشگاه علم و صنعت ایران</a></li>
                                <li><a href="#">رویدادهای دانشگاه تهران</a></li>
                                <li><a href="#">رویدادهای دانشگاه صنعتی خواجه نصیرالدین طوسی</a></li>
                            </ul>
                            </div>

                        </div>
                        <hr className="line"/>
                    {/* third row*/}
                        <div className="row third-row">

                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list namad">
                           <ul>
                            <li><img src={pic4}/></li> 
                            <li id="enamad"><img src={pic5}/></li>   
                            </ul> 
                           
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list list-third">
                           <ul>
                               <li>تماس با تیم پشتیبانی</li>
                               <li id="icon"><button><span>43407877</span><img src={pic}/></button></li>
                               <li>اپلیکیشن برگزارکنندگان</li>
                               <li><img src={pic2}/></li>
                               <li>اپلیکیشن شرکت‌کنندگان</li>
                               <li><img src={pic3}/></li>
                           </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                                <h2>در وبلاگ ایوند بخوانید</h2>
                            <ul>
                                <li><a href="#">چرا رویداد</a></li>
                                <li><a href="#">برنامه‌ریزی و برگزاری رویداد</a></li>
                                <li><a href="#">از رویدادها چه خبر؟</a></li>
                                <li><a href="#">فروش و بازاریابی رویداد</a></li>
                                <li><a href="#">خبرهای ایوند</a></li>
                            </ul>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 list">
                                <h2>استفاده از ایوند</h2>
                            <ul>
                                <li><a href="#">ایجاد رویداد در ایوند</a></li>
                                <li><a href="#">سوالات متداول برگزارکنندگان رویداد</a></li>
                                <li><a href="#">سوالات متداول شرکت‌کنندگان رویداد</a></li>
                                <li><a href="#">شرایط استفاده</a></li>
                                <li><a href="#">تعرفه‌ها</a></li>
                            </ul>
                            </div>

                        </div>
                        <hr className="line"/>

                        {/*row end*/}
                        <div className="row row-end">
                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 social">
                            <a href="#"><img src={pic6}/></a>    
                            <a href="#"><img src={pic7}/></a> 
                            <a href="#"><img src={pic8}/></a> 
                            <a href="#"><img src={pic9}/></a> 
                            <a href="#"><img src={pic10}/></a> 
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 contact">
                            <ul>
                                <li><a href="#">درباره ایوند</a></li>
                                <li><a href="#">بلاگ</a></li>
                                <li><a href="#">تماس با ایوند</a></li>
                                <li><a href="#">همکاری درفروش رویداد</a></li>
                                <li><a href="#">ابزار و ویژگی‌ها</a></li>
                                <li><a href="#">راهنما</a></li>
                            </ul>
                            </div>

                        </div>

                    </div>
                     
                </div>
            </div>
           
        )
    }
}

export default Footer
