import React from 'react';
import './Rooydadha.css'
import Footer from '../Footer/Footer.js';
import pic from '../img/1.jpg'
import pic4 from '../img/2.jpg'
import pic1 from '../img/calendar.svg'
import pic2 from '../img/maps-and-location.svg'
import pic3 from '../img/price.svg'
import pic5 from '../img/3.jpg'
import pic6 from '../img/4.jpg'
import pic7 from '../img/5.jpg'
import pic8 from '../img/6.jpg'
import pic9 from '../img/7.jpg'
import pic10 from '../img/8.jpg'
import pic11 from '../img/9.jpg'
import pic12 from '../img/10.jpg'
import pic13 from '../img/11.jpg'
import pic14 from '../img/12.jpg'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.min.css'
import Titr from '../Titr/Titr.js';
class Rooydadha extends React.Component {
    constructor(props){
        super(props)
        this.state={
         responsive:{
             0: {
                 items: 1,
             },
             450: {
                 items: 1,
             },
             600: {
                 items: 1,
             },
             1000: {
                 items: 1,
             },
         }
        }
    }
    render() {
        return (
            <div className="rooydadha">
                <div className="rooydad-wrapper"> 
                     <div className="container">
                         <div className="row slide">
                         <OwlCarousel
                className="owl-theme"
                items="1"
                autoplay
                nav
                
                responsive={this.state.responsive}
                loop
                margin={30}
                >
                
                        <div className="item">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pic">
                                <img src={pic}/>
                            </div>
                             <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 info">
                            <h2>سازو کارهای شروع کسب و کار موفق</h2>

                            <ul className="list">
                            <li><img src={pic1}/>شروع رویداد<span>چهارشنبه 2 بهمن ساعت 15</span></li>
                            <li><img src={pic2}/>مکان رویداد<span>تهران</span></li>
                            <li><img src={pic3}/>قیمت بلیط<span>رایگان</span></li>
                               
                            </ul>
                            <button>ثبت نام</button>
                             </div>
                        </div>

                        <div className="item">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pic">
                                <img src={pic4}/>
                            </div>
                             <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 info">
                            <h2>سازو کارهای شروع کسب و کار موفق</h2>

                            <ul className="list">
                            <li><img src={pic1}/>شروع رویداد<span>چهارشنبه 2 بهمن ساعت 15</span></li>
                            <li><img src={pic2}/>مکان رویداد<span>تهران</span></li>
                            <li><img src={pic3}/>قیمت بلیط<span>رایگان</span></li>
                               
                            </ul>
                            <button>ثبت نام</button>
                             </div>
                        </div>

                        <div className="item">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pic">
                                <img src={pic}/>
                            </div>
                             <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 info">
                            <h2>سازو کارهای شروع کسب و کار موفق</h2>

                            <ul className="list">
                            <li><img src={pic1}/>شروع رویداد<span>چهارشنبه 2 بهمن ساعت 15</span></li>
                            <li><img src={pic2}/>مکان رویداد<span>تهران</span></li>
                            <li><img src={pic3}/>قیمت بلیط<span>رایگان</span></li>
                               
                            </ul>
                            <button>ثبت نام</button>
                             </div>
                        </div>


                    </OwlCarousel>
                        

                            
                              
                         </div>
{/* next step*/}

<div className="select">
<div className="row">
<div className="options">
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 ">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 once">
           
           <select>
               <option>محبوب ترین</option>
               <option>زمان</option>
               <option>علاقه مندی</option>
               
           </select>
           <span>مرتب‌سازی براساس</span>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 twice">
            
           <select>
               <option>محبوب ترین</option>
               <option>زمان</option>
               <option>علاقه مندی</option>
               </select>
               <span>جستجوی پیشرفته</span>
            </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 search">
            <button>جستجو</button>
            <input placeholder="عنوان شهر را جستجو کنید"/>
            </div>
    </div>

{/*section2*/}

<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 part-two">

<div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">

<select>
    <option>قیمت همه</option>
    <option>رایگان</option>
    <option>تا 50 هزار تومان</option>
    <option> هزار تا 200تومان 50</option>
    <option> هزار تومان به بالا 200</option>
    <option></option>
</select>
</div>
<div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
<select>
    <option>انتخاب موضوع </option>
    <option>تکنولوژی</option>
    <option>کارافرینی</option>
    <option>فرهنگی</option>
    <option>گردشگری</option>
    <option>کسب و کار</option>
</select>
</div>
<div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
<select>
    <option>انتخاب شهر</option>
    <option>تهران</option>
    <option>اصفهان</option>
    <option>تبریز</option>
    <option>کرمان</option>
    <option>کرمانشاه</option>
    <option>یزد</option>
    <option>سیستان</option>
    <option>مشهد</option>
    <option>خوزستان</option>
</select>
</div>
<div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 filter">
<span>فیلتر براساس</span>
</div>

</div>

<hr id="distance"/>




</div>

</div>




</div>


{/* boxes*/}



                     </div>
                </div>
                <Titr/>
            <div className="container">
            <div className="row">
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic5}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic8}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic6}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic7}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic8}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic9}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic10}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic11}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic12}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic13}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic14}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 same">
    <img src={pic5}/>
    <h4>وبینار بررسی سهام بورس ایران</h4>
    <ul>
        <li>تهران</li>
        <li>تا 30دی </li>
        <li>رایگان</li>
    </ul>
    <span>برگزار کننده<br/>آکادمی بورس با ما</span>
    <button>ثبت نام</button>
    </div>
            <button id="btn">رویدادهای بیشتر</button>
</div>

            </div>

                 <Footer/>
            </div>
        );
    }
}

export default Rooydadha;