import React from 'react';
import './Main.css'
import Header from '../Header/Header.js';
import Titr from '../Titr/Titr.js';
import Content from '../Content/Content.js';
import Category from '../Category/Category.js';
import Background from '../Background/Background.js';
import Footer from '../Footer/Footer.js';


export default class Main extends React.Component{

render() {
    return (
        <div>
          <Header/>  
          <Titr/>
          <Content/>
          <Category/>
          <Background/>
         <Footer/>
        </div>
    )
}

}