import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import pic from '../img/download.png'
import pic2 from '../img/menu.svg'
import pic3 from '../img/close.svg'
import search from '../img/search.svg'
import $ from 'jquery'
export default class Navbar extends React.Component {
   
    componentDidMount(){
       $(document).ready(function(){
           var size =window.screen.width
           
           var a=true
           $(".menu").click(function(){
               if(a==true){
                   $(".navbar nav ul").css({'right':'0','transition':'0.5s ease all'})
                   a=false
               }else{
                $(".navbar nav ul").css({'right':'-100%','transition':'1s ease all'})
                a=true
               }

           })
           if(size<992){
            $(".navbar .second .search").click(function(){
                $(".pop-search").show()

                
            })
            $(".pop-search #click").click(function(){
                $(".pop-search").hide()
            })
                
           
           }

          
       })
    }

    render() {
        return (
            <div className="navbar">

                <a href="#" className="menu"><img src={pic2}/></a>
                <div className="first">
                    <nav>
                <ul>
                <li><Link  to="/">صفحه اصلی</Link></li>
                    <li><Link to="/login">ورود</Link></li>
                    <li><Link to="/Membership">عضویت</Link></li>
                    <li id="btn2"><Link to="/login">ایجاد رویداد</Link></li>
                    <li><Link to="/events">رویدادها</Link></li>
                    <li><Link to="/The organizers">برگزار کننده ها</Link></li>
                    <li><Link to="/Ticket">دریافت سریع بلیت</Link></li>
                   
                
                </ul>
            </nav>

           
                    </div>
           
            <div className="second">
            <a href="#" ><img src={pic} className="brand"/></a>
            <input type="search" placeholder="جستجو..."/>
          <button type="submit"><img src={search} className="search"/></button> 
            
            </div>

            <div className="pop-search">
       <a href="#" id="click"><img src={pic3}/></a>
        <input type="text" placeholder="جستجو..."/>
    </div>
            </div>
            

            
        )
    }
}
