import React from 'react';
import './App.css';

import Navbar from './component/Navbar/Navbar';
import { BrowserRouter as Router,Switch,Route } from 'react-router-dom';
import Main from './component/Main/Main.js';
import Voroud from './component/voroud/Voroud.js';
import Ozviat from './component/Ozviat/Ozviat.js';
import Rooydadha from './component/Rooydadha/Rooydadha.js';
import Ticket from './component/Ticket/Ticket.js'
import Bargozar from './component/Bargozar/Bargozar.js';



function App() {
  return (
    <div className="App">
     <Router>
<Navbar/>

<Switch>
<Route exact path="/" component={Main}/>
<Route path="/login" component={Voroud}/>
<Route path="/Membership" component={Ozviat}/>
<Route path="/login" component={Voroud}/>
<Route path="/events" component={Rooydadha}/>
<Route path="/The organizers" component={Bargozar}/>
<Route path="/Ticket" component={Ticket}/>

</Switch>




     </Router>
   
     
    </div>
  );
}

export default App;
