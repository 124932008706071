import React from 'react'
import './Voroud.css'
import { Link } from 'react-router-dom'
import pic from '../img/telegram.svg'
import pic1 from '../img/google-plus.svg'
import pic2 from '../img/linkedin.svg'
class Voroud extends React.Component {
    

    render() {
        return (
            <div className="voroud">
                <div className="wrapper">

               
                <div className="popup">
                <div className="link">
                <ul>
                     <li><Link to="/Membership">عضویت</Link></li>
                    <li><Link to="/login">ورود</Link></li>
                    
                </ul>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 form">
                    <h3>یا با ایمیل خود وارد شوید و یا اگر هنوز ثبت نام نکرده اید ثبت‌نام کنید</h3>
                <form>
                <input type="text" placeholder="ایمیل"/>
                <input type="text" placeholder="کلمه عبور"/>
                <button>ورود</button>

                </form>
                <hr id="distance"/>
                <p>رمز عبور خود را فراموش کرده‌اید؟ <a href="#">کلیک  کنید</a></p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 btn">
                <h3>با استفاده از شبکه های اجتماعی خود وارد شوید</h3>
                <button id="one">ورود با تلگرام<img src={pic}/></button>
                <button id="two">ورود با گوگل<img src={pic1}/></button>
                <button id="three">ورود با لینکدین<img src={pic2}/></button>
                
                </div>
                </div>
                </div>
            </div>
        )
    }
}

export default Voroud
