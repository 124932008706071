import React from 'react';
import './Ticket.css'
class Ticket extends React.Component {
    render() {
        return (
            <div className="ticket">
                <div className="all">
                <div className="center">
                    <h3>دریافت سریع بلیت</h3>
                    <p>شماره موبایل یا ایمیلی که هنگام خرید بلیت وارد کردید را وارد کنید.</p>
                    <input type="text" placeholder="ایمیل و شماره تلفن خود را وارد کنید"/>
                    <button>مرحله بعد</button>

                </div>
                </div>
              
            </div>
        );
    }
}

export default Ticket;