import React from 'react'
import './Background.css'
import pic from '../img/pic1.png'
import pic2 from '../img/r7c-logo.png'
import pic3 from '../img/867-logo.png'
import pic4 from '../img/mlx-logo.png'
import pic5 from '../img/lj6-logo.png'
import pic6 from '../img/nhz-logo.png'
import pic7 from '../img/9ca-logo.png'
class Background extends React.Component {
    
    

    render() {
        return (
            <div className="background">
                <div className="container">
                <div className="row">
                <div className="back-second">
                <h4>ابزارهایی که ایوند در اختیار برگزارکنندگان قرار می‌دهد</h4>
                <div className="box-midddle">
                    
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 img">
                    <img src={pic}/>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                   <h3>ایجاد رویداد</h3>
                   <p>می‌توانید به سادگی رویدادتان را در ایوند ایجاد کرده و -ضمن دریافت یک صفحه با آدرس اختصاصی- 
                       اطلاعات مختلفی مثل زمان برگزاری، مکان رویداد همراه با نقشه‌ی گوگل،
                        قیمت بلیت‌ها و سایر مشخصات رویداد را در معرض دید مخاطبین قرار دهید.</p>
                </div>
                <button>مشاهده همه ویژگی های ایوند</button>
                </div>
{/*second box*/}
                <div className="distance"></div>
                    <h5>محبوب ترین برگزارکنندگان</h5>
                <div className="brand">

                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                <img src={pic2}/>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                <img src={pic3}/>
                </div>

                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                <img src={pic4}/>
                </div>

                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                <img src={pic5}/>
                </div>

                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                <img src={pic6}/>
                </div>

                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                <img src={pic7}/>
                </div>
                </div>

               


                </div>
                </div>
                </div>
               
            </div>
           
        )
    }
}

export default Background
