import React from 'react';
import './Content.css'
import pic1 from '../img/0ce71a3250fa19860570605a23d6b9b7.jpg'
import pic2 from '../img/c11fda6ce917609f8ca79c41e86796e4.jpg'
class Content extends React.Component {
    render() {
        return (
            <div className="content">
                <div className="wraper-content">

                <div className="container">
                <div className="row">
                    <h3>محبوب‌ترین‌های این هفته | <a href="#">مشاهده همه</a></h3>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 second-box">
               <div className="min-box">
                <img src={pic1}/>
                <h4>یک روز ویژه از نزدیک با "فرهاد فزونی"</h4>
                <ul>
                    <li><a href="#">تهران</a></li>
                    <li><a href="#">جمعه 27 دی</a></li>
                    <li><a href="#">رایگان</a></li>
                </ul>
                <p>برگزار کننده<br/>مدرسه ویژه</p>
               </div>


                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="min-box">
                <img src={pic2}/>
                <h4>یک روز ویژه از نزدیک با "فرهاد فزونی"</h4>
                <ul>
                    <li><a href="#">تهران</a></li>
                    <li><a href="#">جمعه 27 دی</a></li>
                    <li><a href="#">رایگان</a></li>
                </ul>
                <p>برگزار کننده<br/>مدرسه ویژه</p>
               </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 first-box">

                    <ul>
                        <li>
                        <div className="box">
                        <i>24 دی</i>
                        <div className="caption">
                        <h4>گردهمایی بزرگ پرشین</h4>
                        <p>چالوس -سه شنبه</p>
                        <span>55,000تومان</span>
                        </div>
                    <hr/>
                    </div>
                        </li>

                        <li>
                        <div className="box">
                        <i>25 دی</i>
                        <div className="caption">
                        <h4>گردهمایی بزرگ پرشین</h4>
                        <p>چالوس -سه شنبه</p>
                        <span>55,000تومان</span>
                        </div>
                        <hr/>
                    </div>
                        </li>

                        <li>
                        <div className="box">
                        <i>26 دی</i>
                        <div className="caption">
                        <h4>گردهمایی بزرگ پرشین</h4>
                        <p>چالوس -سه شنبه</p>
                        <span>55,000تومان</span>
                        </div>
                        <hr/>
                    </div>
                        </li>

                        <li>
                        <div className="box">
                        <i>28 دی</i>
                        <div className="caption">
                        <h4>گردهمایی بزرگ پرشین</h4>
                        <p>چالوس -سه شنبه</p>
                        <span>55,000تومان</span>
                        </div>
                        
                    </div>
                        </li>
                    </ul>
                   

                   
                </div>
               
                </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Content;