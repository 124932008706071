import React from 'react';
import './Ozviat.css'
import { Link } from 'react-router-dom'
import pic from '../img/telegram.svg'
import pic1 from '../img/google-plus.svg'
import pic2 from '../img/linkedin.svg'
class Ozviat extends React.Component {
    render() {
        return (
            <div className="ozviat">
                <div className="wrapper">

               
<div className="popup-two">
<div className="link">
<ul>
     <li><Link to="/Membership">عضویت</Link></li>
    <li><Link to="/login">ورود</Link></li>
    
</ul>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 form">
    <h3>یا با ایمیل خود ثبت نام کنید</h3>
<form>
<input type="text" placeholder="ایمیل"/>
<input type="text" placeholder="کلمه عبور"/>
<input type="text" placeholder="شماره موبایل"/>
<input type="text" placeholder="ایمیل"/>
<input type="text" placeholder="کلمه عبور"/>
<input type="text" placeholder="تکرار کلمه عبور"/>

<span> با عضویت در ایوند،‌‌ شرایط و قوانین سایت را می‌پذیرم&nbsp;</span><input type="checkbox"/><button>عضویت</button>


</form>
<hr id="distance"/>
<p>رمز عبور خود را فراموش کرده‌اید؟ <a href="#">کلیک  کنید</a></p>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 btn">
<h3>با استفاده از شبکه های اجتماعی خود وارد شوید</h3>
<button id="one">ورود با تلگرام<img src={pic}/></button>
<button id="two">ورود با گوگل<img src={pic1}/></button>
<button id="three">ورود با لینکدین<img src={pic2}/></button>

</div>
</div>
</div>
            </div>
        );
    }
}

export default Ozviat;