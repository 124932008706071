import React from 'react'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './Header.css'
import pic1 from '../img/f754a58039f331648928a03db86472d1.jpg'
import pic2 from '../img/f79bd2c81f72bb77188ab706bf372c1c.jpg'
import pic3 from '../img/f4f1b307a2b73348896ceba5f8b0e97b.jpg'
import pic5 from '../img/e56d383a99fe3e2cefd761e7c3a680c0.jpg'
import pic4 from '../img/ece847b12f7d105999d124eab2ceedfe.jpg'
class Header extends React.Component {
   constructor(props){
       super(props)
       this.state={
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
       }
   }

    render() {
        return (
            <div className="header">

               
                 <OwlCarousel
                className="owl-theme"
                items="3"
                autoplay
                nav
                
                responsive={this.state.responsive}
                loop
                margin={30}
                
                >
            <div className="item">
           <div className="picture">
                <img src={pic1}/>
                <button>ثبت نام</button>
                <p>جلسه معارفه رایگان گروه درمان چاقی باراکا</p>
                <ul>
                    <li>تهران</li>
                    <li>27دی</li><br/>
                    <li>رایگان</li>
                </ul>
                </div>
            </div>

            <div className="item">
           <div className="picture">
                <img src={pic2}/>
                <button>ثبت نام</button>
                <p>جلسه معارفه رایگان گروه درمان چاقی باراکا</p>
                <ul>
                    <li>تهران</li>
                    <li>27دی</li><br/>
                    <li>رایگان</li>
                </ul>
                </div>
            </div>

            <div className="item">
            <div className="picture">
                <img src={pic3}/>
                <button>ثبت نام</button>
                <p>جلسه معارفه رایگان گروه درمان چاقی باراکا</p>
                <ul>
                    <li>تهران</li>
                    <li>27دی</li><br/>
                    <li>رایگان</li>
                </ul>
                </div>
            </div>

            <div className="item">
            <div className="picture">
                <img src={pic4}/>
                <button>ثبت نام</button>
                <p>جلسه معارفه رایگان گروه درمان چاقی باراکا</p>
                <ul>
                    <li>تهران</li>
                    <li>27دی</li><br/>
                    <li>رایگان</li>
                </ul>
                </div>
            </div>

            <div className="item">
            <div className="picture">
                <img src={pic5}/>
                <button>ثبت نام</button>
                <p>جلسه معارفه رایگان گروه درمان چاقی باراکا</p>
                <ul>
                    <li>تهران</li>
                    <li>27دی</li><br/>
                    <li>رایگان</li>
                </ul>
                </div>
            </div>

           

                </OwlCarousel> 
                </div>
        )
    }
}

export default Header
