import React from 'react';
import './Category.css'
import pic1 from '../img/shortcuts-01 (1).jpg'
import pic2 from '../img/shortcuts-01 (2).jpg'
import pic3 from '../img/shortcuts-01 (3).jpg'
import pic4 from '../img/shortcuts-01 (4).jpg'
import pic5 from '../img/shortcuts-01 (5).jpg'
import pic6 from '../img/shortcuts-011.jpg'
class Category extends React.Component {
    render() {
        return (
            <div className="category">
                 
                 <div className="container">
                    <div className="row">
                    <h3>محبوب‌ترین‌های دسته بندی ها | <a href="#">مشاهده همه</a></h3>
                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 cat">
                    <img src={pic1}/>
                    <h4>تکنولوژی</h4>
                   </div>

                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 cat">
                   <img src={pic2}/>
                   <h4>فرهنگی هنری</h4>
                   </div>

                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 cat">
                   <img src={pic3}/>
                   <h4>مدیریت</h4>
                   </div>

                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 cat">
                   <img src={pic4}/>
                   <h4>کارافرینی</h4>
                   </div>

                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 cat">
                   <img src={pic5}/>
                   <h4>فنی مهندسی</h4>
                   </div>

                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 cat">
                   <img src={pic6}/>
                   <h4>کسب و کار</h4>
                   </div>
                    </div>
                 </div>
                

            </div>
        );
    }
}

export default Category;