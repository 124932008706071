import React from 'react';
import './Bargozar.css'
import Footer from '../Footer/Footer';
import pic from '../img/search2.svg'
import pic2 from '../img/small.jpg'
import pic3 from '../img/small2.jpg'
import pic4 from '../img/small3.jpg'
import pic5 from '../img/small4.jpg'
import pic6 from '../img/small5.jpg'
import pic7 from '../img/small6.jpg'
import pic8 from '../img/small7.jpg'
import pic9 from '../img/small8.jpg'
import pic10 from '../img/small9.jpg'
import pic11 from '../img/small10.jpg'
import pic12 from '../img/small11.jpg'
import pic13 from '../img/small12.jpg'
class Bargozar extends React.Component {
    render() {
        return (
            <div className="bargozar">
                <div className="head">
                    <div className="container">
                        <div className="row">
                        <h2>لیست برگزارکنندگان</h2>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 search">
                    <button><img src={pic}/></button>
                    <input type="text" placeholder="جستجو"/>
                    </div>
                        </div>

                    </div>
                   

                </div>
                <div className="container">
                    <div className="row">
                        <div className="box-info">
                            <div className="sub-box">
                                <img src={pic2}/>
                                <h4>بنیاد ملی بازی‌های رایانه‌ای</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>100</span></li> 
                                    <li>دنبال کنندگان<br/><span>11458</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic3}/>
                                <h4>کارگزاری مفید</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>94</span></li> 
                                    <li>دنبال کنندگان<br/><span>9449</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic4}/>
                                <h4>بنیاد ملی بازی‌های رایانه‌ای</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>100</span></li> 
                                    <li>دنبال کنندگان<br/><span>11458</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic5}/>
                                <h4>بزرگترین گروه آموزشی کشور (مثبت6)</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>681</span></li> 
                                    <li>دنبال کنندگان<br/><span>6737</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic6}/>
                                <h4>سپیدار سیستم</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>141</span></li> 
                                    <li>دنبال کنندگان<br/><span>6113</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic7}/>
                                <h4>آکادمی دیجیتال مارکتینگ</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>513</span></li> 
                                    <li>دنبال کنندگان<br/><span>6013</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic8}/>
                                <h4>همفکر تهران</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>237</span></li> 
                                    <li>دنبال کنندگان<br/><span>5563</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic9}/>
                                <h4>کرامت</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>55</span></li> 
                                    <li>دنبال کنندگان<br/><span>4810</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic10}/>
                                <h4>بنیاد ملی بازی‌های رایانه‌ای</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>100</span></li> 
                                    <li>دنبال کنندگان<br/><span>11458</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic11}/>
                                <h4>پارک فناوری پردیس</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>2</span></li> 
                                    <li>دنبال کنندگان<br/><span>4546</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic12}/>
                                <h4>بنیاد ملی بازی‌های رایانه‌ای</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>100</span></li> 
                                    <li>دنبال کنندگان<br/><span>11458</span></li>
                                </ul>

                            </div>

                            <div className="sub-box">
                                <img src={pic13}/>
                                <h4>( تیم تحلیل آنلاین )</h4>
                                <ul>
                                    <li>رویداد های برگزار شده<br/><span>41</span></li> 
                                    <li>دنبال کنندگان<br/><span>3498</span></li>
                                </ul>

                            </div>
                            
                            <button id="btn">برگزار کنندگان بیشتر</button>

                        </div>

                    </div>

                </div>
               <Footer/>
            </div>
        );
    }
}

export default Bargozar;